// Feed Element

$(function(){
	var $feed = $("#feed");
	
	if (!$feed.length) return;
	
	// Featured Posts
	
	var $featuredPosts = $feed.find('.featured-posts')
	
	if ($featuredPosts.length) {
		var $wrap = $featuredPosts.find('.wrap')
		var $posts = $feed.find('.post.featured')
		var $slideCount = $featuredPosts.find('.slide-count')
		
		var index = 0,
			postCount = $posts.length;
		
		function slide(distance){
			index += distance;
			
			if (index == postCount)
				index = 0;
			
			if (index < 0)
				index = postCount-1;
			
			$wrap.css({
					transform: 'translateX(-'+(index * 100)+'%)'
				})
			
			$slideCount.children().removeClass('selected').eq(index).addClass('selected');
		}
		
		// Events
		
		$featuredPosts.find('.left-arrow')
			.on('click', function(){
				clearInterval(timerInterval);
				slide(-1);
			})
		
		$featuredPosts.find('.right-arrow')
			.on('click', function(){
				clearInterval(timerInterval);
				slide(1);
			})
		
		
		// Set Up
		
		$posts.each(function(){
			$slideCount.append('<span></span>');
		})
		$slideCount.children().eq(0).addClass('selected');
		
		if (postCount) {
			$featuredPosts.show();
			$posts.clone().appendTo($wrap);
		}
		
		
		// Timer
		
		var timerInterval = setInterval(function(){
			slide(1);
		}, 5000)
	}
	
	// More Button
	
	// How many posts to show at each button click
	var increment = $feed.data('increment')
	
	if (increment) {
		var $moreButton = $('<button class="button subtle fullwidth more-button">View More</button>')
		var $posts = $feed.find('.post')
		
		// How many posts to shown
		var visibleCount = 0
		
		// Append more button
		$feed.append($moreButton)
		
		// Set up handler for button
		$moreButton.on('click', loadMorePosts)
		
		// Hide all posts
		$posts.addClass('hidden')
		
		// Load initial posts
		loadMorePosts()
		
		function loadMorePosts() {
			visibleCount += increment
			$posts.slice(0, visibleCount).removeClass('hidden')
			
			if (visibleCount >= $posts.length) {
				$moreButton.addClass('hidden')
			}
		}
	}
	
	// Popup Photos
	
	$('.popup-photo').magnificPopup({
		type: 'image',
	})
})