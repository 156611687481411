var jade = require("jade/runtime");

module.exports = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (photoId, url) {
buf.push("<div class=\"photo\">");
if ( typeof photoId !== 'undefined')
{
var photoIdUrl = '/media/photo?id='+photoId+'&geo=128x128^';
}
buf.push("<div" + (jade.attr("style", "background-image: url(" + (url || photoIdUrl) + ")", true, false)) + " class=\"photo-container\"><img" + (jade.attr("src", url || photoIdUrl, true, false)) + "/></div>");
if ( typeof photoId !== 'undefined')
{
buf.push("<span class=\"delete-button\">Remove</span>");
}
buf.push("</div>");}.call(this,"photoId" in locals_for_with?locals_for_with.photoId:typeof photoId!=="undefined"?photoId:undefined,"url" in locals_for_with?locals_for_with.url:typeof url!=="undefined"?url:undefined));;return buf.join("");
};