$(function(){
	// Confirmation Anchors
	$('a.confirm').click(function(ev){
		var message = $(this).data('message');
		if (!confirm(message))
		{
			ev.stopPropagation();
			ev.preventDefault();
			return false;
		}
	});
	
	// Form Anchors
	$(document)
		.on('click', 'a[rev=form]', function(ev){
			ev.preventDefault();
			
			var $anchor = $(this);
			var dataStr = $anchor.data('body');
			var dataArr = dataStr.split(/&|,/g);
			var fields = {};
			
			for (var i=0; i < dataArr.length; ++i)
			{
				var dataEleStr = dataArr[i];
				dataEleArr = dataEleStr.split('=');
				fields[dataEleArr[0]] = decodeURIComponent(dataEleArr[1]);
			}
			
			var $form = $("<form action='"+$anchor.attr('href')+"' method='post'></form>");
			for (var name in fields)
			{
				var value = fields[name];
				$form.append("<input type='hidden' name='"+name+"' value='"+value+"'>");
			}
			
			$form.appendTo('body');
			$form.submit();
		});
})