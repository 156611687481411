// Install bootstrap
require('bootstrap');

require('./header.js');
require('./social-buttons.js');
require('./elements.js');

require('./pages.js');
require('./classifieds.js');


$(function(){
	$('#view-switcher .switch')
		.on('click', function(){
			$(this).siblings().each(function(){
				$(this).removeClass('active');
				$('html').removeClass($(this).data('class'));
			})
			
			$(this).addClass('active');
			
			$('html').addClass($(this).data('class'));
		})
})