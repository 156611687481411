var photoPreviewTemplate = require('../views/classifieds/parts/photo-preview.jade');

$(function(){
	if (!$('body').is('.page-classifieds')) return;
	
	// Location Search Field
	var $locationField = $('.location-field');
	
	$locationField.select2({
		placeholder: $locationField.data('placeholder')
	});
})

$(function(){
	if (!$('body').is('.page-classifieds-create')) return;
	
	$('.photo-previews').on('click', '.photo .delete-button', function(){
		if (confirm("Are you sure you want to remove this photo? (Photo will be removed when changes are saved)")) {
			$(this).parents('.photo').remove();
		}
	})
	
	// Category Field
	
	var $categoryField = $('.category-field');
	
	$categoryField.italianSelect();
	
	// Tags Field
	var $tagsField = $('.tags-field');
	
	$tagsField
		.each(function(){
			$(this)
				.select2({
					placeholder: $(this).attr('placeholder'),
					tags: false
				})
		})
		.on('focus', function(){
			console.log('tags focus')
		});
	
	// Location Field
	var $locationField = $('.location-field');
	
	$locationField.select2({
		placeholder: $locationField.attr('placeholder'),
		maximumSelectionLength: 1,
		tags: true
	});
	
	// Photo Previews
	var $photoPreviews = $('.photo-previews');
	
	if (window.File && window.FileList && window.FileReader) {
		$("#field-photos").on('change', function(ev){
			var files = ev.target.files;
			
			$photoPreviews.removeClass('hidden').empty();
			
			for (var i = 0; i < files.length; i++) {
				var file = files[i];
				
				//Only pics
				if (!file.type.match('image')) continue;

				var picReader = new FileReader();
				
				picReader.addEventListener("load", function (ev) {
					var picFile = ev.target;
					var div = document.createElement("div");
					
					var photoPreview = photoPreviewTemplate({
						url: picFile.result
					})
					
					$photoPreviews.append(photoPreview);
				});
				
				//Read the image
				picReader.readAsDataURL(file);
			}
		});
	}
	else {
		if (!$photoPreviews.children().length) {
			$photoPreviews.hide();
		}
	}
	
	// Help Boxes
	$('.help-box-activator')
		.on('click focus', function(){
			var $button = $(this),
				hbSelector = $button.data('help-box'),
				$hb = $(hbSelector);
			
			$('.help-box').removeClass('active')
			$hb.addClass('active');
			
			var topOffset = $button.offset().top - $hb.parents().offset().top;
			
			$hb.css('top', topOffset + 'px');
		})
})

$(function(){
	if (!$('body').is('.page-classified')) return;
	
	// Body Markdown
	
	var marked = require('marked');
	
	var $body = $('.body'),
		html = marked($body.text(), {
			sanitize: true
		});
	
	$body.html(html);
	
	
	$('.contact-button').magnificPopup({
		type: 'inline',
		midClick: true,
		mainClass: 'mfp-fade',
		removalDelay: 300
	})
	
	var $contactForm = $('#contact-popup .contact-form'),
		$responseArea = $('#contact-popup .response-area');
	
	$contactForm.ajaxForm({
		resetForm: true,
		success: function(msg){
			$contactForm.find(':input').removeAttr('disabled');
			contactFormResponse(msg);
		},
		error: function(xhr){
			$contactForm.find(':input').removeAttr('disabled');
			
			if (xhr.status === 400)
				contactFormErrorResponse(xhr.responseText);
			else
				contactFormErrorResponse("Something went wrong. Please try again.");
		},
		beforeSubmit: function(arr, $form){
			$contactForm.find(':input').attr('disabled', 'disabled');
		}
	})
	
	function contactFormErrorResponse(msg){
		var $p = $("<p/>").addClass('warn').text(msg);
		
		$responseArea.empty().append($p);
	}
	
	function contactFormResponse(msg){
		var $p = $("<p/>").text(msg);
		
		$responseArea.empty().append($p);
	}
})